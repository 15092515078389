
import { defineComponent, ref, toRefs } from "vue";
import accountComponents from "../accountComponents";
import { setTokenAccountOwner } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  name: accountComponents.SetOwner,
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const settingOwner = ref(false);
    const accountAddress = ref("");
    const currentOwnerSecret = ref("");
    const currentOwnerSignsExternally = ref(true);
    const newOwnerAddress = ref("");

    const onSetOwner = async () => {
      settingOwner.value = true;
      emit("update:accountAddress", "");
      try {
        await setTokenAccountOwner(
          payerSecret.value,
          accountAddress.value,
          currentOwnerSecret.value,
          newOwnerAddress.value,
          payerSignsExternally.value,
          currentOwnerSignsExternally.value
        );
        emit("update:accountAddress", accountAddress.value);
      } catch (err) {
        settingOwner.value = false;
        throw err;
      }
      settingOwner.value = false;
    };

    return {
      settingOwner,
      accountAddress,
      onSetOwner,
      currentOwnerSecret,
      newOwnerAddress,
      currentOwnerSignsExternally
    };
  }
});
