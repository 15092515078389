
import { defineComponent, ref, toRefs } from "vue";
import { burnTokens } from "@/solana/token";
import accountComponents from "../accountComponents";
import { u64 } from "@solana/spl-token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  name: accountComponents.Burn,
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },

    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const ownerAccountSecret = ref("");
    const ownerSignsExternally = ref(true);
    const accountAddress = ref("");
    const burningTokens = ref(false);
    const tokenAmount = ref("");

    const onBurnTokens = async () => {
      burningTokens.value = true;
      emit("update:accountAddress", "");
      try {
        await burnTokens(
          payerSecret.value,
          accountAddress.value,
          ownerAccountSecret.value,
          new u64(tokenAmount.value, 10),
          payerSignsExternally.value,
          ownerSignsExternally.value
        );
        emit("update:accountAddress", accountAddress.value);
      } catch (err) {
        burningTokens.value = false;
        throw err;
      }
      burningTokens.value = false;
    };

    return {
      burningTokens,
      tokenAmount,
      ownerAccountSecret,
      accountAddress,
      onBurnTokens,
      ownerSignsExternally
    };
  }
});
