
import { defineComponent, ref, toRefs } from "vue";
import { transferTokens } from "@/solana/token";
import accountComponents from "../accountComponents";
import { u64 } from "@solana/spl-token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  components: { SecretFormField, PublicKeyFormField },
  name: accountComponents.Transfer,
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const ownerAccountSecret = ref("");
    const ownerSignsExternally = ref(true);

    const destinationAccountAddress = ref("");
    const sourceAccountAddress = ref("");
    const transferring = ref(false);
    const tokenAmount = ref("");

    const onTransferTokens = async () => {
      transferring.value = true;
      emit("update:accountAddress", "");
      try {
        await transferTokens(
          payerSecret.value,
          sourceAccountAddress.value,
          destinationAccountAddress.value,
          ownerAccountSecret.value,
          new u64(tokenAmount.value, 10),
          payerSignsExternally.value,
          ownerSignsExternally.value
        );
        emit("update:accountAddress", destinationAccountAddress.value);
      } catch (err) {
        transferring.value = false;
        throw err;
      }
      transferring.value = false;
    };

    return {
      destinationAccountAddress,
      sourceAccountAddress,
      transferring,
      onTransferTokens,
      tokenAmount,
      ownerAccountSecret,
      ownerSignsExternally
    };
  }
});
