
import { defineComponent, ref, toRefs } from "vue";
import accountComponents from "../accountComponents";
import { closeAccount } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  name: accountComponents.Close,
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const closingAccount = ref(false);
    const accountAddress = ref("");
    const ownerSecret = ref("");
    const ownerSignsExternally = ref(true);
    const destinationAccountAddress = ref("");

    const onCloseAccount = async () => {
      closingAccount.value = true;
      emit("update:accountAddress", "");
      try {
        await closeAccount(
          payerSecret.value,
          accountAddress.value,
          destinationAccountAddress.value,
          ownerSecret.value,
          payerSignsExternally.value,
          ownerSignsExternally.value
        );
        emit("update:accountAddress", accountAddress.value);
      } catch (err) {
        closingAccount.value = false;
        throw err;
      }
      closingAccount.value = false;
    };

    return {
      closingAccount,
      accountAddress,
      onCloseAccount,
      ownerSecret,
      destinationAccountAddress,
      ownerSignsExternally
    };
  }
});
