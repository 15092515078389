
import { defineComponent, ref, toRefs } from "vue";
import accountComponents from "../accountComponents";
import { freezeAccount } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  name: accountComponents.Freeze,
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },

    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const freezingAccount = ref(false);
    const accountToFreezeAddress = ref("");
    const freezeAuthoritySecret = ref("");
    const freezeAuthoritySignsExternally = ref(true);

    const onFreezeAccount = async () => {
      freezingAccount.value = true;
      emit("update:accountAddress", "");
      try {
        await freezeAccount(
          payerSecret.value,
          accountToFreezeAddress.value,
          freezeAuthoritySecret.value,
          payerSignsExternally.value,
          freezeAuthoritySignsExternally.value
        );
        emit("update:accountAddress", accountToFreezeAddress.value);
      } catch (err) {
        freezingAccount.value = false;
        throw err;
      }
      freezingAccount.value = false;
    };

    return {
      freezingAccount,
      accountToFreezeAddress,
      onFreezeAccount,
      freezeAuthoritySecret,
      freezeAuthoritySignsExternally
    };
  }
});
