
import { defineComponent, ref, toRefs } from "vue";
import { mintToken } from "@/solana/token";
import accountComponents from "../accountComponents";
import { u64 } from "@solana/spl-token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  name: accountComponents.Mint,
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const mintAuthoritySecret = ref("");
    const mintAuthoritySignsExternally = ref(true);

    const destinationAccountAddress = ref("");
    const mintingToAccount = ref(false);
    const tokenAmount = ref("");

    const mintToAccount = async () => {
      mintingToAccount.value = true;
      emit("update:accountAddress", "");
      try {
        await mintToken(
          payerSecret.value,
          mintAuthoritySecret.value,
          destinationAccountAddress.value,
          new u64(tokenAmount.value, 10),
          payerSignsExternally.value,
          mintAuthoritySignsExternally.value
        );
        emit("update:accountAddress", destinationAccountAddress.value);
      } catch (err) {
        mintingToAccount.value = false;
        throw err;
      }
      mintingToAccount.value = false;
    };

    return {
      destinationAccountAddress,
      mintingToAccount,
      mintToAccount,
      mintAuthoritySecret,
      tokenAmount,
      mintAuthoritySignsExternally
    };
  }
});
