
import { defineComponent, ref, toRefs } from "vue";
import { u64 } from "@solana/spl-token";
import accountComponents from "../accountComponents";
import { approveDelegate } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";

export default defineComponent({
  name: accountComponents.Approve,
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  emits: ["update:accountAddress"],
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const approvingDelegate = ref(false);
    const accountAddress = ref("");
    const ownerSecret = ref("");
    const ownerSignsExternally = ref(true);
    const delegateAddress = ref("");
    const tokenAmount = ref("");

    const onApproveDelegate = async () => {
      approvingDelegate.value = true;
      emit("update:accountAddress", "");
      try {
        await approveDelegate(
          payerSecret.value,
          payerSignsExternally.value,
          ownerSecret.value,
          ownerSignsExternally.value,
          accountAddress.value,
          delegateAddress.value,
          new u64(tokenAmount.value, 10)
        );
        emit("update:accountAddress", accountAddress.value);
      } catch (err) {
        approvingDelegate.value = false;
        throw err;
      }
      approvingDelegate.value = false;
    };

    return {
      approvingDelegate,
      accountAddress,
      onApproveDelegate,
      ownerSecret,
      delegateAddress,
      ownerSignsExternally,
      tokenAmount
    };
  }
});
